._styles__container__1H7C6 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

._Status__container__Adysl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

#_Status__incomingSmall__2mcKC {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.7em; }

#_Status__incomingMedium__77wmf {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.9em; }

#_Status__incomingLarge__2OJv0 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1em; }

#_Status__dialpad__24i7u {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  transition: all 0.3s ease, opacity 0.2s ease; }
  #_Status__dialpad__24i7u._Status__closed__3nIZK {
    visibility: hidden;
    opacity: 0;
    height: 0; }

._Status__statusLarge__3G14Z {
  padding: 2px;
  font-size: 25px; }

._Status__dialpadButtonSmall__5yJF4 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px;
  font-size: 1em; }
  ._Status__dialpadButtonSmall__5yJF4:focus {
    outline: none;
    box-shadow: none; }
  ._Status__dialpadButtonSmall__5yJF4:active {
    transition: none;
    opacity: 0.5; }
  ._Status__dialpadButtonSmall__5yJF4:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__dialpadButtonMedium__oUPm8 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px;
  font-size: 1.2em; }
  ._Status__dialpadButtonMedium__oUPm8:focus {
    outline: none;
    box-shadow: none; }
  ._Status__dialpadButtonMedium__oUPm8:active {
    transition: none;
    opacity: 0.5; }
  ._Status__dialpadButtonMedium__oUPm8:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__dialpadButtonLarge__2hCkj {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px;
  font-size: 1.5em; }
  ._Status__dialpadButtonLarge__2hCkj:focus {
    outline: none;
    box-shadow: none; }
  ._Status__dialpadButtonLarge__2hCkj:active {
    transition: none;
    opacity: 0.5; }
  ._Status__dialpadButtonLarge__2hCkj:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__dialpadButtonLetters__N-jqm {
  font-size: 0.5em;
  color: #495057; }

._Status__dialpadRow__19SxG {
  display: flex;
  flex-direction: row; }

#_Status__actionButtonSmall__NY-3t {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  #_Status__actionButtonSmall__NY-3t:focus {
    outline: none;
    box-shadow: none; }
  #_Status__actionButtonSmall__NY-3t:active {
    transition: none;
    opacity: 0.5; }
  #_Status__actionButtonSmall__NY-3t:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_Status__actionButtonSmall__NY-3t._Status__on__3ZwLv {
    background-color: #495057; }

#_Status__actionButtonMedium__QlUP6 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  #_Status__actionButtonMedium__QlUP6:focus {
    outline: none;
    box-shadow: none; }
  #_Status__actionButtonMedium__QlUP6:active {
    transition: none;
    opacity: 0.5; }
  #_Status__actionButtonMedium__QlUP6:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_Status__actionButtonMedium__QlUP6._Status__on__3ZwLv {
    background-color: #495057; }

#_Status__actionButtonLarge__CsQpv {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  #_Status__actionButtonLarge__CsQpv:focus {
    outline: none;
    box-shadow: none; }
  #_Status__actionButtonLarge__CsQpv:active {
    transition: none;
    opacity: 0.5; }
  #_Status__actionButtonLarge__CsQpv:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_Status__actionButtonLarge__CsQpv._Status__on__3ZwLv {
    background-color: #495057; }

._Status__endCallButtonSmall__8Ja3- {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._Status__endCallButtonSmall__8Ja3-:focus {
    outline: none;
    box-shadow: none; }
  ._Status__endCallButtonSmall__8Ja3-:active {
    transition: none;
    opacity: 0.5; }
  ._Status__endCallButtonSmall__8Ja3-:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__endCallButtonMedium__3K9EK {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._Status__endCallButtonMedium__3K9EK:focus {
    outline: none;
    box-shadow: none; }
  ._Status__endCallButtonMedium__3K9EK:active {
    transition: none;
    opacity: 0.5; }
  ._Status__endCallButtonMedium__3K9EK:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__endCallButtonLarge__1rxit {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._Status__endCallButtonLarge__1rxit:focus {
    outline: none;
    box-shadow: none; }
  ._Status__endCallButtonLarge__1rxit:active {
    transition: none;
    opacity: 0.5; }
  ._Status__endCallButtonLarge__1rxit:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__startCallButtonSmall__2LJYU {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._Status__startCallButtonSmall__2LJYU:focus {
    outline: none;
    box-shadow: none; }
  ._Status__startCallButtonSmall__2LJYU:active {
    transition: none;
    opacity: 0.5; }
  ._Status__startCallButtonSmall__2LJYU:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__startCallButtonMedium__J4Xe3 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._Status__startCallButtonMedium__J4Xe3:focus {
    outline: none;
    box-shadow: none; }
  ._Status__startCallButtonMedium__J4Xe3:active {
    transition: none;
    opacity: 0.5; }
  ._Status__startCallButtonMedium__J4Xe3:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__startCallButtonLarge__z8JxO {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._Status__startCallButtonLarge__z8JxO:focus {
    outline: none;
    box-shadow: none; }
  ._Status__startCallButtonLarge__z8JxO:active {
    transition: none;
    opacity: 0.5; }
  ._Status__startCallButtonLarge__z8JxO:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__actionsContainer__2kDeL {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

#_Status__transferMenu__1yjIy {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease; }
  #_Status__transferMenu__1yjIy._Status__closed__3nIZK {
    visibility: hidden;
    opacity: 0;
    height: 0; }

#_Status__transferInput__2tho8 {
  border: none;
  padding: none;
  font-size: 1em;
  border: 2px solid #495057;
  border-radius: 100px;
  width: 80%;
  padding: 5px 10px 5px 10px; }
  #_Status__transferInput__2tho8:focus {
    outline: none; }

._Status__transferButtonSmall__hNLnI {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._Status__transferButtonSmall__hNLnI:focus {
    outline: none;
    box-shadow: none; }
  ._Status__transferButtonSmall__hNLnI:active {
    transition: none;
    opacity: 0.5; }
  ._Status__transferButtonSmall__hNLnI:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__transferButtonMedium__3xVYU {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._Status__transferButtonMedium__3xVYU:focus {
    outline: none;
    box-shadow: none; }
  ._Status__transferButtonMedium__3xVYU:active {
    transition: none;
    opacity: 0.5; }
  ._Status__transferButtonMedium__3xVYU:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__transferButtonLarge__YyaP5 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._Status__transferButtonLarge__YyaP5:focus {
    outline: none;
    box-shadow: none; }
  ._Status__transferButtonLarge__YyaP5:active {
    transition: none;
    opacity: 0.5; }
  ._Status__transferButtonLarge__YyaP5:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Status__container__Adysl {
  width: 100%;
  margin-top: 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

#_Status__settingsButton__3TfJl {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background: none;
  height: 24px;
  width: 24px;
  padding: 2px; }
  #_Status__settingsButton__3TfJl:focus {
    outline: none;
    box-shadow: none; }
  #_Status__settingsButton__3TfJl:active {
    transition: none;
    opacity: 0.5; }
  #_Status__settingsButton__3TfJl:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_Status__settingsButton__3TfJl._Status__on__3ZwLv {
    background-color: #ADB5BD; }

#_Status__settingsMenu__6JtnT {
  flex-direction: column;
  transition: all 0.2s ease;
  width: 100%;
  justify-content: flex-start; }
  #_Status__settingsMenu__6JtnT._Status__closed__3nIZK {
    visibility: hidden;
    opacity: 0;
    height: 0; }

._Status__dropdownRow__2NuIJ {
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 0; }

._Status__dropdownIcon__1K5Gw {
  padding: 0 5px 0 5px; }

._UserString__container__351uz {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

#_UserString__incomingSmall__35MuX {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.7em; }

#_UserString__incomingMedium__2TYqW {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.9em; }

#_UserString__incomingLarge__3nIx5 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1em; }

#_UserString__dialpad__OZlk_ {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  transition: all 0.3s ease, opacity 0.2s ease; }
  #_UserString__dialpad__OZlk_._UserString__closed__hCG-D {
    visibility: hidden;
    opacity: 0;
    height: 0; }

._UserString__statusLarge__3-0Jr {
  padding: 2px;
  font-size: 25px; }

._UserString__dialpadButtonSmall__1G6xM {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px;
  font-size: 1em; }
  ._UserString__dialpadButtonSmall__1G6xM:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__dialpadButtonSmall__1G6xM:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__dialpadButtonSmall__1G6xM:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__dialpadButtonMedium__3VWlP {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px;
  font-size: 1.2em; }
  ._UserString__dialpadButtonMedium__3VWlP:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__dialpadButtonMedium__3VWlP:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__dialpadButtonMedium__3VWlP:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__dialpadButtonLarge__J28b4 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px;
  font-size: 1.5em; }
  ._UserString__dialpadButtonLarge__J28b4:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__dialpadButtonLarge__J28b4:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__dialpadButtonLarge__J28b4:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__dialpadButtonLetters__25ZAm {
  font-size: 0.5em;
  color: #495057; }

._UserString__dialpadRow__3q0-9 {
  display: flex;
  flex-direction: row; }

#_UserString__actionButtonSmall__3cxMH {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  #_UserString__actionButtonSmall__3cxMH:focus {
    outline: none;
    box-shadow: none; }
  #_UserString__actionButtonSmall__3cxMH:active {
    transition: none;
    opacity: 0.5; }
  #_UserString__actionButtonSmall__3cxMH:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_UserString__actionButtonSmall__3cxMH._UserString__on__3LWDa {
    background-color: #495057; }

#_UserString__actionButtonMedium__1bC52 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  #_UserString__actionButtonMedium__1bC52:focus {
    outline: none;
    box-shadow: none; }
  #_UserString__actionButtonMedium__1bC52:active {
    transition: none;
    opacity: 0.5; }
  #_UserString__actionButtonMedium__1bC52:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_UserString__actionButtonMedium__1bC52._UserString__on__3LWDa {
    background-color: #495057; }

#_UserString__actionButtonLarge__Y7b9y {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  #_UserString__actionButtonLarge__Y7b9y:focus {
    outline: none;
    box-shadow: none; }
  #_UserString__actionButtonLarge__Y7b9y:active {
    transition: none;
    opacity: 0.5; }
  #_UserString__actionButtonLarge__Y7b9y:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_UserString__actionButtonLarge__Y7b9y._UserString__on__3LWDa {
    background-color: #495057; }

._UserString__endCallButtonSmall__2sQop {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._UserString__endCallButtonSmall__2sQop:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__endCallButtonSmall__2sQop:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__endCallButtonSmall__2sQop:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__endCallButtonMedium__1oRJk {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._UserString__endCallButtonMedium__1oRJk:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__endCallButtonMedium__1oRJk:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__endCallButtonMedium__1oRJk:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__endCallButtonLarge__3csc6 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._UserString__endCallButtonLarge__3csc6:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__endCallButtonLarge__3csc6:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__endCallButtonLarge__3csc6:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__startCallButtonSmall__5349J {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._UserString__startCallButtonSmall__5349J:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__startCallButtonSmall__5349J:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__startCallButtonSmall__5349J:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__startCallButtonMedium__2qzLL {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._UserString__startCallButtonMedium__2qzLL:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__startCallButtonMedium__2qzLL:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__startCallButtonMedium__2qzLL:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__startCallButtonLarge__1awK0 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._UserString__startCallButtonLarge__1awK0:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__startCallButtonLarge__1awK0:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__startCallButtonLarge__1awK0:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__actionsContainer__1S5ik {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

#_UserString__transferMenu__B_RC3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease; }
  #_UserString__transferMenu__B_RC3._UserString__closed__hCG-D {
    visibility: hidden;
    opacity: 0;
    height: 0; }

#_UserString__transferInput__2INh- {
  border: none;
  padding: none;
  font-size: 1em;
  border: 2px solid #495057;
  border-radius: 100px;
  width: 80%;
  padding: 5px 10px 5px 10px; }
  #_UserString__transferInput__2INh-:focus {
    outline: none; }

._UserString__transferButtonSmall__1WxUQ {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._UserString__transferButtonSmall__1WxUQ:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__transferButtonSmall__1WxUQ:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__transferButtonSmall__1WxUQ:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__transferButtonMedium__2rYd7 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._UserString__transferButtonMedium__2rYd7:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__transferButtonMedium__2rYd7:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__transferButtonMedium__2rYd7:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__transferButtonLarge__30xds {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._UserString__transferButtonLarge__30xds:focus {
    outline: none;
    box-shadow: none; }
  ._UserString__transferButtonLarge__30xds:active {
    transition: none;
    opacity: 0.5; }
  ._UserString__transferButtonLarge__30xds:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._UserString__container__351uz {
  width: 100%;
  margin-top: 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

#_UserString__settingsButton__1zv_c {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background: none;
  height: 24px;
  width: 24px;
  padding: 2px; }
  #_UserString__settingsButton__1zv_c:focus {
    outline: none;
    box-shadow: none; }
  #_UserString__settingsButton__1zv_c:active {
    transition: none;
    opacity: 0.5; }
  #_UserString__settingsButton__1zv_c:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_UserString__settingsButton__1zv_c._UserString__on__3LWDa {
    background-color: #ADB5BD; }

#_UserString__settingsMenu__1r_CB {
  flex-direction: column;
  transition: all 0.2s ease;
  width: 100%;
  justify-content: flex-start; }
  #_UserString__settingsMenu__1r_CB._UserString__closed__hCG-D {
    visibility: hidden;
    opacity: 0;
    height: 0; }

._UserString__dropdownRow__K0aBl {
  display: flex;
  flex-direction: row;
  margin: 5px 0 5px 0; }

._UserString__dropdownIcon__2YbUI {
  padding: 0 5px 0 5px; }

._UserString__userStringSmall__3xSlp {
  padding: 2px;
  font-size: 15px; }

._UserString__userStringMedium__26mN7 {
  padding: 2px;
  font-size: 20px; }

._UserString__userStringLarge__3pYEI {
  padding: 2px;
  font-size: 25px; }

._Phone__container__33s4p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

#_Phone__incomingSmall__Fzw8L {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.7em; }

#_Phone__incomingMedium__2mM_j {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.9em; }

#_Phone__incomingLarge__Ad_CD {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1em; }

#_Phone__dialpad__-iUpI {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  transition: all 0.3s ease, opacity 0.2s ease; }
  #_Phone__dialpad__-iUpI._Phone__closed__1Yn0M {
    visibility: hidden;
    opacity: 0;
    height: 0; }

._Phone__statusLarge__3n9O3 {
  padding: 2px;
  font-size: 25px; }

._Phone__dialpadButtonSmall__3aOAk {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px;
  font-size: 1em; }
  ._Phone__dialpadButtonSmall__3aOAk:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__dialpadButtonSmall__3aOAk:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__dialpadButtonSmall__3aOAk:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__dialpadButtonMedium__39TL3 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px;
  font-size: 1.2em; }
  ._Phone__dialpadButtonMedium__39TL3:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__dialpadButtonMedium__39TL3:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__dialpadButtonMedium__39TL3:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__dialpadButtonLarge__3LPOh {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px;
  font-size: 1.5em; }
  ._Phone__dialpadButtonLarge__3LPOh:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__dialpadButtonLarge__3LPOh:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__dialpadButtonLarge__3LPOh:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__dialpadButtonLetters__30C7x {
  font-size: 0.5em;
  color: #495057; }

._Phone__dialpadRow__ftZ8R {
  display: flex;
  flex-direction: row; }

#_Phone__actionButtonSmall__2M9aX {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  #_Phone__actionButtonSmall__2M9aX:focus {
    outline: none;
    box-shadow: none; }
  #_Phone__actionButtonSmall__2M9aX:active {
    transition: none;
    opacity: 0.5; }
  #_Phone__actionButtonSmall__2M9aX:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_Phone__actionButtonSmall__2M9aX._Phone__on__11LDZ {
    background-color: #495057; }

#_Phone__actionButtonMedium__2mr3P {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  #_Phone__actionButtonMedium__2mr3P:focus {
    outline: none;
    box-shadow: none; }
  #_Phone__actionButtonMedium__2mr3P:active {
    transition: none;
    opacity: 0.5; }
  #_Phone__actionButtonMedium__2mr3P:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_Phone__actionButtonMedium__2mr3P._Phone__on__11LDZ {
    background-color: #495057; }

#_Phone__actionButtonLarge__7z8Lb {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  #_Phone__actionButtonLarge__7z8Lb:focus {
    outline: none;
    box-shadow: none; }
  #_Phone__actionButtonLarge__7z8Lb:active {
    transition: none;
    opacity: 0.5; }
  #_Phone__actionButtonLarge__7z8Lb:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }
  #_Phone__actionButtonLarge__7z8Lb._Phone__on__11LDZ {
    background-color: #495057; }

._Phone__endCallButtonSmall__3OpQZ {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._Phone__endCallButtonSmall__3OpQZ:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__endCallButtonSmall__3OpQZ:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__endCallButtonSmall__3OpQZ:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__endCallButtonMedium__tWpoE {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._Phone__endCallButtonMedium__tWpoE:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__endCallButtonMedium__tWpoE:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__endCallButtonMedium__tWpoE:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__endCallButtonLarge__1MsjV {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #e5383b;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._Phone__endCallButtonLarge__1MsjV:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__endCallButtonLarge__1MsjV:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__endCallButtonLarge__1MsjV:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__startCallButtonSmall__3srXs {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._Phone__startCallButtonSmall__3srXs:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__startCallButtonSmall__3srXs:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__startCallButtonSmall__3srXs:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__startCallButtonMedium__2nTvK {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._Phone__startCallButtonMedium__2nTvK:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__startCallButtonMedium__2nTvK:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__startCallButtonMedium__2nTvK:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__startCallButtonLarge__zuLWK {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #ced4da;
  margin: 10px;
  background-color: #80ed99;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._Phone__startCallButtonLarge__zuLWK:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__startCallButtonLarge__zuLWK:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__startCallButtonLarge__zuLWK:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__actionsContainer__25gV2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

#_Phone__transferMenu__1yYD- {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease; }
  #_Phone__transferMenu__1yYD-._Phone__closed__1Yn0M {
    visibility: hidden;
    opacity: 0;
    height: 0; }

#_Phone__transferInput__ovMXl {
  border: none;
  padding: none;
  font-size: 1em;
  border: 2px solid #495057;
  border-radius: 100px;
  width: 80%;
  padding: 5px 10px 5px 10px; }
  #_Phone__transferInput__ovMXl:focus {
    outline: none; }

._Phone__transferButtonSmall__12d_H {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 40px;
  height: 40px;
  margin: 5px; }
  ._Phone__transferButtonSmall__12d_H:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__transferButtonSmall__12d_H:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__transferButtonSmall__12d_H:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__transferButtonMedium__jQyfb {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 50px;
  height: 50px;
  margin: 8px; }
  ._Phone__transferButtonMedium__jQyfb:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__transferButtonMedium__jQyfb:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__transferButtonMedium__jQyfb:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Phone__transferButtonLarge__3kTg5 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px;
  width: 60px;
  height: 60px;
  margin: 10px; }
  ._Phone__transferButtonLarge__3kTg5:focus {
    outline: none;
    box-shadow: none; }
  ._Phone__transferButtonLarge__3kTg5:active {
    transition: none;
    opacity: 0.5; }
  ._Phone__transferButtonLarge__3kTg5:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Dialstring__container__2iAE_ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

._Dialstring__dialButton__3GsXr {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  height: 40px;
  width: 40px;
  background: none; }
  ._Dialstring__dialButton__3GsXr:focus {
    outline: none;
    box-shadow: none; }
  ._Dialstring__dialButton__3GsXr:active {
    transition: none;
    opacity: 0.5; }
  ._Dialstring__dialButton__3GsXr:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Dialstring__dialButtonStrict__tfL15 {
  border: 0;
  padding: 0;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  transition: all 0.2s;
  transition-delay: 300ms;
  background-color: #ccdbfd;
  color: #495057;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  height: 80px;
  width: 80px;
  background: none; }
  ._Dialstring__dialButtonStrict__tfL15:focus {
    outline: none;
    box-shadow: none; }
  ._Dialstring__dialButtonStrict__tfL15:active {
    transition: none;
    opacity: 0.5; }
  ._Dialstring__dialButtonStrict__tfL15:hover {
    transition: all 0.1s;
    transition-delay: 100ms;
    filter: brightness(70%); }

._Dialstring__dialInput__32AFz {
  border: none;
  padding: none;
  font-size: 1em;
  width: 100%; }
  ._Dialstring__dialInput__32AFz:focus {
    outline: none; }

._Dialstring__dialstringContainerStrict__2qSFk {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  padding: 0 0 0 60px; }

._Dialstring__dialstringInlineDiv__1bX9L {
  display: flex;
  flex-direction: row;
  width: 100%; }

._Dialstring__dialstringInlineInput__1wsrX {
  flex: 1;
  border: 1px solid #ADB5BD;
  padding: 5px;
  max-width: 300px;
  min-width: 100px;
  background-color: white;
  border-radius: 10px;
  font-size: 0.9em; }

._Dialstring__dialstringInlineButton__3aieU {
  padding: 2px;
  border: none;
  background: white;
  cursor: pointer; }
